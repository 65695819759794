<template>
    <div id="tool-layout">
        <keep-alive>
            <router-view></router-view>
        </keep-alive>
        
    </div>
</template>
<script>
export default {
    name: "Tool",
    data() {
        return {
        };
    },
    activated() {
        // console.log("activated, tool",);
    },
};
</script>
<style lang="less">
    #tool-layout{
        flex-grow: 1;
    }
</style>
